const RATIO = {
  standard: 1,
  '9:16': 16 / 9,
};
Object.freeze(RATIO);

const INFINITES_TYPES = {
  AI: 'AI',
  IRL: 'IRL',
};
Object.freeze(INFINITES_TYPES);

const INFINITES_COUNT = {
  AI: 512,
  IRL: 1024,
};
Object.freeze(INFINITES_COUNT);

const S3_BASE_URL = {
  AI: 'https://infinites.s3.us-east-2.amazonaws.com',
  IRL: 'https://irl-infinites.s3.us-east-2.amazonaws.com',
};
Object.freeze(S3_BASE_URL);

const CollectionSortOptions = [
  { value: 'asc', label: 'A to Z' },
  { value: 'desc', label: 'Z to A' },
];
Object.freeze(CollectionSortOptions);

const CollectionNumSortOptions = [
  { value: 'asc', label: 'Low to High' },
  { value: 'desc', label: 'High to Low' },
];
Object.freeze(CollectionNumSortOptions);

const SortSign = { asc: 1, desc: -1 };

const CollectionAttributesWithType = {
  AI: {
    Id: 'number',
    Base: 'string',
    'Base Speed': 'number',
    'Noise Scale': 'number',
    Fluidity: 'string',
    Body: 'string',
  },
  IRL: {
    Id: 'number',
    Base: 'string',
    Surface: 'string',
    Depth: 'string',
    Fluidity: 'string',
    Body: 'number',
  },
};
Object.freeze(CollectionAttributesWithType);

export {
  RATIO,
  INFINITES_TYPES,
  INFINITES_COUNT,
  S3_BASE_URL,
  CollectionSortOptions,
  CollectionNumSortOptions,
  SortSign,
  CollectionAttributesWithType,
};
