import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import fscreen from 'fscreen';
import { isMobile } from 'mobile-device-detect';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { IconButton } from '@material-ui/core';
import { useStyles } from './styles';

const FullScreenCanvas = ({ disabledRotate = true, renderCanvas, threeControlsRef }) => {
  const classes = useStyles();
  const fullScreenHandle = useFullScreenHandle();
  const [isManualFullScreen, setManualFullScreen] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);

  const handleFullScreenClick = () => {
    if (fscreen.fullscreenEnabled) fullScreenHandle.enter();
    else setManualFullScreen(true);
  };

  // useEffect(() => {
  //   return () => document.documentElement.classList.remove('vm-scroll--lock');
  // }, []);
  useEffect(() => {
    if (threeControlsRef?.current) threeControlsRef.current.trigger();
    // document.documentElement.classList.toggle('vm-scroll--lock', isManualFullScreen);
  }, [isManualFullScreen, threeControlsRef]);

  const timerRef = useRef(null);
  const [visibleExitFullScreenBtn, setVisibleExitFullScreenBtn] = useState(false);
  const handleFullScreenWrapperMouseMove = (e) => {
    if (fullScreenHandle.active || isManualFullScreen) {
      if (!isMobile) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }

        setVisibleExitFullScreenBtn(true);
        timerRef.current = setTimeout(() => {
          setVisibleExitFullScreenBtn(false);
          timerRef.current = null;
        }, 3000);
      }
    }
  };
  const handleFullScreenWrapperClick = () => {
    if (fullScreenHandle.active || isManualFullScreen) {
      if (isMobile) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          setVisibleExitFullScreenBtn(false);
          return;
        }

        setVisibleExitFullScreenBtn(true);
        timerRef.current = setTimeout(() => {
          setVisibleExitFullScreenBtn(false);
          timerRef.current = null;
        }, 3000);
      }
    }
  };

  const handleExitFullScreenClick = () => {
    if (fscreen.fullscreenEnabled) {
      fullScreenHandle.exit();
      setRotateDeg(0);
    } else {
      setManualFullScreen(false);
    }
    setVisibleExitFullScreenBtn(false);
  };

  const handleFullScreenOnChange = (status, handle) => {
    if ((visibleExitFullScreenBtn || rotateDeg !== 0) && !status) {
      setRotateDeg(0);
      setVisibleExitFullScreenBtn(false);
    }
  };

  const handleRotate = (dir) => () => {
    if (fullScreenHandle.active) {
      setRotateDeg((deg) => (deg * dir === 270 ? 0 : deg + dir * 90));
    }
  };

  useEffect(() => {
    if (threeControlsRef?.current && fullScreenHandle.active) {
      const { trigger } = threeControlsRef.current;
      if ([90, -90, 270, -270].includes(rotateDeg)) {
        trigger(-1); // landscape
      } else {
        trigger();
      }
    }
  }, [rotateDeg, fullScreenHandle, threeControlsRef]);

  return (
    <FullScreen
      handle={fullScreenHandle}
      // onChange?: (state: boolean, handle: FullScreenHandle) => void;
      onChange={handleFullScreenOnChange}
      className={classNames('fs__wrapper', {
        'fs--manual': isManualFullScreen,
      })}
    >
      <div
        className='width--full height--full vm--center'
        onMouseMove={handleFullScreenWrapperMouseMove}
        onClick={handleFullScreenWrapperClick}
      >
        {visibleExitFullScreenBtn && (
          <div className={classes.fullscreenExitBtnWrapper}>
            <IconButton
              aria-label='Exit full screen'
              className={classNames(classes.fullScreenBtn, classes.mr1)}
              onClick={handleExitFullScreenClick}
            >
              <FullscreenExitIcon />
            </IconButton>
            {!isMobile && !disabledRotate && (
              <>
                <IconButton
                  aria-label='Rotate left 90deg'
                  className={classNames(classes.fullScreenBtn, classes.mr1)}
                  onClick={handleRotate(-1)}
                >
                  <RotateLeftIcon />
                </IconButton>
                <IconButton
                  aria-label='Rotate right 90deg'
                  className={classNames(classes.fullScreenBtn, classes.mr1)}
                  onClick={handleRotate(1)}
                >
                  <RotateRightIcon />
                </IconButton>
              </>
            )}
          </div>
        )}

        {renderCanvas(
          <div className='fs__btn-wrapper'>
            {!fullScreenHandle.active && !isManualFullScreen && (
              <IconButton
                aria-label='Full screen'
                className={classes.fullScreenBtn}
                onClick={handleFullScreenClick}
              >
                <FullscreenIcon />
              </IconButton>
            )}
          </div>,
          rotateDeg
        )}
      </div>
    </FullScreen>
  );
};

export default FullScreenCanvas;
