import { useEffect, useState } from 'react';

const getwindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getwindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getwindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
