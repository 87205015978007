import React, { memo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Button, Hidden, IconButton, withStyles, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterIcon from '@material-ui/icons/Filter';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import ContactsIcon from '@material-ui/icons/Contacts';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import NavigationDrawer from './NavigationDrawer';
import classNames from 'classnames';
import { useMoralis } from 'react-moralis';

const styles = (theme) => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  appBar: {
    // boxShadow: theme.shadows[6],
    boxShadow: 'none !important',
    backgroundColor: 'transparent',
  },
  toolbar: {
    height: 220,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 70,
    },
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  mr40: {
    marginRight: 40,
  },
  mlAuto: {
    marginLeft: 'auto',
  },
  menuButton: {
    marginLeft: 'auto',
  },
});

function NavBar(props) {
  const { classes, handleMobileDrawerOpen, handleMobileDrawerClose, mobileDrawerOpen } = props;
  // const { enqueueSnackbar } = useSnackbar();

  const { authenticate, isAuthenticated, logout, user, enableWeb3, isWeb3Enabled } = useMoralis();

  const loadWeb3 = useCallback(() => {
    enableWeb3();
  }, [enableWeb3]);
  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled) {
      loadWeb3();
    }
    if (isWeb3Enabled && !isAuthenticated) {
      authenticate();
    }
  }, [isWeb3Enabled, authenticate, isAuthenticated, loadWeb3]);

  const setupUser = () => {
    if (isAuthenticated) {
      logout();
    } else {
      loadWeb3();
    }
  };

  const menuItems = [
    {
      link: '/',
      name: 'infinites',
      icon: <DashboardIcon className='text-white' />,
    },
    {
      link: '/about',
      name: 'about',
      icon: <ContactsIcon className='text-white' />,
    },
    // {
    //   link: '/terms-conditions',
    //   name: 'terms & conditions',
    //   icon: <PlaylistAddCheckIcon className='text-white' />,
    // },
  ];

  const MobileMenuItems = [
    {
      link: '/',
      name: 'infinites',
      icon: <DashboardIcon className='text-white' />,
    },
    {
      link: '/collection',
      name: 'AI collection',
      icon: <BurstModeIcon className='text-white' />,
    },
    {
      link: '/irl/collection',
      name: 'IRL collection',
      icon: <FilterIcon className='text-white' />,
    },
    {
      link: '/about',
      name: 'about',
      icon: <ContactsIcon className='text-white' />,
    },
    // {
    //   link: '/terms-conditions',
    //   name: 'terms & conditions',
    //   icon: <ContactsIcon className='text-white' />,
    // },
  ];

  menuItems.push({
    name: (user && user.get('ethAddress').substring(0, 6)) || 'connect wallet',
    onClick: () => {
      setupUser();
    },
    icon: <AccountBalanceWalletIcon className='text-white' />,
  });

  MobileMenuItems.push({
    name: (user && user.get('ethAddress').substring(0, 6)) || 'connect wallet',
    onClick: () => {
      setupUser();
    },
    icon: <AccountBalanceWalletIcon className='text-white' />,
  });

  return (
    <div className={classes.root}>
      <AppBar position='relative' className={classes.appBar}>
        <Box
          display='flex'
          alignItems='center'
          position='relative'
          className={classNames(classes.toolbar, 'container-fluid')}
        >
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              onClick={handleMobileDrawerOpen}
              aria-label='Open Navigation'
            >
              <MenuIcon color='primary' />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {menuItems.map((element) =>
              element.link ? (
                <Link
                  key={element.name}
                  to={element.link}
                  className={classes.noDecoration}
                  onClick={handleMobileDrawerClose}
                >
                  <Button
                    color='secondary'
                    size='large'
                    variant='outlined'
                    className={classes.mr40}
                  >
                    {element.name}
                  </Button>
                </Link>
              ) : (
                <Button
                  color='primary'
                  variant='contained'
                  size='large'
                  onClick={element.onClick}
                  key={element.name}
                  className={classes.mlAuto}
                >
                  {element.name}
                </Button>
              )
            )}
          </Hidden>
        </Box>
      </AppBar>
      <NavigationDrawer
        menuItems={MobileMenuItems}
        anchor='right'
        open={mobileDrawerOpen}
        onClose={handleMobileDrawerClose}
      />
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleMobileDrawerOpen: PropTypes.func,
  handleMobileDrawerClose: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(memo(NavBar));
