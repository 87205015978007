import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  '@global': {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    '*:focus': {
      outline: 0,
    },
    '.vm-scroll--lock': {
      overflow: 'hidden !important',
    },
    '.vm-overflow-visible': {
      overflow: 'visible !important',
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.container': {
      width: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170,
      },
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    '.container-fluid': {
      width: '100%',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 1700,
    },

    '.border--radius-5': {
      borderRadius: 5,
    },

    '.color--gray3F': {
      color: '#3F3D67 !important',
    },
    '.color--gray1A': {
      color: '#1A1A1A !important',
    },
    '.color--gray19': {
      color: '#191919 !important',
    },

    '.font--archivo': {
      fontFamily: 'Archivo !important',
    },
    '.font--space-grotesk': {
      fontFamily: '"Space Grotesk" !important',
    },
    '.vm--m-0': {
      margin: 0,
    },
    '.vm--position-absolute': {
      position: 'absolute',
    },
    '.width--full': {
      width: '100%',
    },
    '.height--full': {
      height: '100%',
    },
    '.vm--center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    // Full screen mode
    // '.fs__wrapper.fullscreen-enabled .fs__btn-wrapper': {
    //   display: 'none !important',
    // },
    '.fs__btn-wrapper': {
      display: 'none',
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    '.fs__wrapper.fs--manual': {
      zIndex: 1102,
      inset: 0,
      width: '100%',
      position: 'fixed',
      overflowY: 'auto',
      background: '#000',
    },
    '.fs__wrapper': {
      '&.fullscreen-enabled, &.fs--manual': {
        display: 'flex',
        '& .fs__canvas': {
          width: 'fit-content !important',
          height: 'fit-content !important',
          margin: 'unset',
        },
      },
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
