import React, { useContext } from "react";
import AWS from 'aws-sdk';

export function useS3() {
    const w = useContext(S3Context);
    if (!w) {
        throw new Error('Missing s3 context');
    }
    return w;
}

const S3Context = React.createContext(null);

export const NFT_CONTRACT_ADDRESS = "0xedCBD212Cb2718259EeB11EbEd9ccc77D19aA59F";

export default function S3Provider(props) {
    AWS.config.region = 'us-east-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-2:a898c6d3-65b5-464e-813e-8476f6a64752',
    });

    let s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "infinites" }
    });

    let irlS3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "irl-infinites" }
    });

    return (
        <S3Context.Provider value={{ s3, irlS3 }}>
            {props.children}
        </S3Context.Provider>
    )
}