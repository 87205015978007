import React from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
// import Pace from './shared/components/Pace';
import routes, { renderRoutes } from './router';
import { MoralisProvider } from 'react-moralis';
import ContractProvider from './blockchain/ContractProvider';
import S3Provider from './s3/S3Provider';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MoralisProvider
          appId='AXP4mNi52vhBSAIfDrpMPp40h5vMuQPz0hvMlY3f'
          serverUrl='https://br5kl4jviwuk.usemoralis.com:2053/server'
        >
          <ContractProvider>
            <S3Provider>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {/* <Pace color={theme.palette.primary.light} /> */}

                {renderRoutes(routes)}
              </MuiThemeProvider>
            </S3Provider>
          </ContractProvider>
        </MoralisProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
