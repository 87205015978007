import { createTheme, responsiveFontSizes } from '@material-ui/core';

// colors
const primary = '#000';
const secondary = '#585858';
const black = '#343a40';
const darkBlack = 'rgb(36, 40, 44)';
const background = '#fff';
const warningLight = 'rgba(253, 200, 69, .3)';
const warningMain = 'rgba(253, 200, 69, .5)';
const warningDark = 'rgba(253, 200, 69, .7)';

// border
const borderWidth = 2;
const borderColor = '#4e4e4ee5';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    text: {
      primary: '#707070',
    },
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
    },
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        transition:
          'border 100ms cubic-bezier(0.65, 0, 0.35, 1) 0s, background 100ms cubic-bezier(0.65, 0, 0.35, 1) 0s, transform 200ms cubic-bezier(0.33, 1, 0.68, 1) 0s',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        background: '#fff',
      },
      label: {
        textTransform: 'none',
      },
      sizeLarge: {
        height: 55,
        borderRadius: 27,
        fontSize: 27,
        lineHeight: '27px',
        fontWeight: 400,
        [`@media (max-width:  1020px)`]: {
          height: 40,
          fontSize: 17,
          lineHeight: '17px',
          fontWeight: 400,
        },
      },
      outlined: {
        border: '2px solid #4E4E4EDC !important',
        boxShadow: '0 3px 6px #00000029',
        '&:hover': {
          backgroundColor: '#f2f2f2 !important',
        },
        '&:active': {
          transform: 'scale(0.95)',
        },
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
      contained: {
        boxShadow: 'none !important',
        '&:hover': {
          boxShadow: 'none !important',
          backgroundColor: '#4D4D4D !important',
        },
        '&:active': {
          transform: 'scale(0.95)',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        position: 'static',
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  typography: {
    fontFamily: ['"Space grotesk"', 'Roboto'].join(','),
    useNextVariants: true,
    h6: {
      fontSize: 65,
      fontWeight: 400,
      lineHeight: 1.3,
      [`@media (max-width:  ${lg}px)`]: {
        fontSize: '50px !important',
      },
      [`@media (max-width:  ${md}px)`]: {
        fontSize: '40px !important',
      },
      [`@media (max-width:  ${sm}px)`]: {
        fontSize: '24px !important',
      },
    },
    subtitle1: {
      fontSize: 38,
      lineHeight: 1.3,
      [`@media (max-width:  ${lg}px)`]: {
        fontSize: '32px !important',
      },
      [`@media (max-width:  ${md}px)`]: {
        fontSize: '26px !important',
      },
      [`@media (max-width:  ${sm}px)`]: {
        fontSize: '20px !important',
      },
    },
    subtitle2: {
      fontSize: 36,
      lineHeight: 1.3,
      fontWeight: 300,
      [`@media (max-width:  ${lg}px)`]: {
        fontSize: '30px !important',
      },
      [`@media (max-width:  ${md}px)`]: {
        fontSize: '24px !important',
      },
      [`@media (max-width:  ${sm}px)`]: {
        fontSize: '18px !important',
      },
    },
    body1: {
      fontSize: 32,
      lineHeight: 1.3,
      [`@media (max-width:  ${lg}px)`]: {
        fontSize: '24px !important',
      },
      [`@media (max-width:  ${md}px)`]: {
        fontSize: '20px !important',
      },
      [`@media (max-width:  ${sm}px)`]: {
        fontSize: '16px !important',
      },
    },
    body2: {
      fontSize: 24,
      lineHeight: 1.3,
      [`@media (max-width:  ${lg}px)`]: {
        fontSize: '20px !important',
      },
      [`@media (max-width:  ${md}px)`]: {
        fontSize: '18px !important',
      },
      [`@media (max-width:  ${sm}px)`]: {
        fontSize: '14px !important',
      },
    },
  },
});

export default responsiveFontSizes(theme);
