import * as THREE from 'three';
import { Color } from 'three';

// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { Water } from 'three/examples/jsm/objects/Water2.js';
import { RATIO } from '../constants';
// import floor from './FloorsCheckerboard_S_Diffuse.jpg';
function WindowResize(renderer, camera, container, ratio) {
  let dimension = function (isFullScreen = false) {
    if (isFullScreen) {
      const containerWidth = container.parentNode.offsetWidth;
      const containerHeight = container.parentNode.offsetHeight;
      if (ratio === RATIO['9:16']) {
        if (window.canvasOrientation === -1)
          // landscape
          return ratio * containerHeight > containerWidth
            ? {
                width: parseFloat(containerWidth / ratio),
                height: containerWidth,
              }
            : {
                width: containerHeight,
                height: parseFloat(containerHeight * ratio),
              };
        else
          return ratio * containerWidth > containerHeight
            ? {
                width: parseFloat(containerHeight / ratio),
                height: containerHeight,
              }
            : {
                width: containerWidth,
                height: parseFloat(containerWidth * ratio),
              };
      }

      const min = Math.min(containerWidth, containerHeight);
      return {
        width: min,
        height: min,
      };
    }

    return {
      width: container.offsetWidth,
      height: parseInt(container.offsetWidth * ratio),
    };
  };
  var callback = function () {
    let isFullScreen = false;
    setTimeout(() => {
      if (
        container.parentNode.parentNode.className.includes('fullscreen-enabled') ||
        container.parentNode.parentNode.className.includes('fs--manual')
      )
        isFullScreen = true;
      else window.canvasOrientation = 1; // portrait
      // fetch target renderer size
      var rendererSize = dimension(isFullScreen);
      // console.log(rendererSize);
      // notify the renderer of the size change
      renderer.setSize(rendererSize.width, rendererSize.height);
      // update the camera
      // camera.aspect = rendererSize.width / rendererSize.height;
      // camera.updateProjectionMatrix();
    }, 0);
  };
  // bind the resize event
  window.addEventListener('resize', callback, false);
  // return .stop() the function to stop watching window resize
  return {
    trigger: function (dir = 1) {
      window.canvasOrientation = dir;
      callback();
    },
    /**
     * Stop watching window resize
     */
    destroy: function () {
      window.removeEventListener('resize', callback);
    },
  };
}

const params = {
  color: '#ffffff',
  scale: 4,
  reflectivity: 0.2,
  torusVisible: false,
  flowX: 1,
  flowY: 1,
};

export function initHome(id, img, ratio, baseSpeed = 0.05, noiseScale = 0.5) {
  var clock = new THREE.Clock();
  let container = document.getElementById(id);

  // SCENE
  let scene = new THREE.Scene();
  scene.background = new Color(1, 1, 1);
  // CAMERA
  var SCREEN_WIDTH = container.offsetWidth,
    SCREEN_HEIGHT = parseInt(container.offsetWidth * ratio);

  let camera = new THREE.OrthographicCamera(
    -SCREEN_WIDTH / 2,
    SCREEN_WIDTH / 2,
    -SCREEN_HEIGHT / 2,
    SCREEN_HEIGHT / 2
  );
  scene.add(camera);
  camera.position.set(0, 0, 400);
  camera.lookAt(scene.position);
  // RENDERER
  // if ( Detector.webgl )
  let renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
  // else
  // 	renderer = new THREE.CanvasRenderer();
  renderer.domElement.id = id + '-threeCanvas';
  renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);
  // EVENTS
  const { destroy: resizeListenerDestroy, trigger } = WindowResize(
    renderer,
    camera,
    container,
    ratio
  );
  // THREEx.FullScreen.bindKey({ charCode : 'm'.charCodeAt(0) });
  // CONTROLS
  let controls = null; // new OrbitControls(camera, renderer.domElement);
  // STATS
  // stats = new Stats();
  // stats.domElement.style.position = 'absolute';
  // stats.domElement.style.bottom = '0px';
  // stats.domElement.style.zIndex = 100;
  // container.appendChild(stats.domElement);
  // LIGHT
  var light = new THREE.PointLight(0xffffff);
  light.position.set(0, 250, 0);
  scene.add(light);
  // // FLOOR
  // var floorTexture = new THREE.TextureLoader().load(checkerboard);
  // floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
  // floorTexture.repeat.set(10, 10);
  // var floorMaterial = new THREE.MeshBasicMaterial({ map: floorTexture, side: THREE.DoubleSide });
  // var floorGeometry = new THREE.PlaneGeometry(1000, 1000, 10, 10);
  // var floor = new THREE.Mesh(floorGeometry, floorMaterial);
  // floor.position.y = -0.5;
  // floor.rotation.x = Math.PI / 2;
  // scene.add(floor);
  // SKYBOX/FOG
  scene.fog = new THREE.FogExp2(0x9999ff, 0.00025);

  ////////////
  // CUSTOM //
  ////////////

  THREE.ImageUtils.crossOrigin = '';

  var noiseLoader = new THREE.TextureLoader();
  noiseLoader.setCrossOrigin('anonymous');
  var noiseTexture = noiseLoader.load(
    'https://infinites.s3.us-east-2.amazonaws.com/image/cloud.png'
  );
  noiseTexture.wrapS = noiseTexture.wrapT = THREE.RepeatWrapping;

  var lavaLoader = new THREE.TextureLoader();
  lavaLoader.setCrossOrigin('anonymous');
  var lavaTexture = lavaLoader.load(img);
  lavaTexture.wrapS = lavaTexture.wrapT = THREE.RepeatWrapping;

  // use "this." to create global object
  let customUniforms = {
    baseTexture: { type: 't', value: lavaTexture },
    baseSpeed: { type: 'f', value: baseSpeed },
    noiseTexture: { type: 't', value: noiseTexture },
    noiseScale: { type: 'f', value: noiseScale },
    alpha: { type: 'f', value: 1.0 },
    time: { type: 'f', value: 1.0 },
  };

  let vertexShader = `
    varying vec2 vUv;
    void main() 
    { 
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `;

  let fragmentShader = `
    uniform sampler2D baseTexture;
    uniform float baseSpeed;
    uniform sampler2D noiseTexture;
    uniform float noiseScale;
    uniform float alpha;
    uniform float time;

    varying vec2 vUv;
    void main() 
    {
        vec2 uvTimeShift = vUv + vec2( -0.7, 1.5 ) * time * baseSpeed;	
        vec4 noiseGeneratorTimeShift = texture2D( noiseTexture, uvTimeShift );
        vec2 uvNoiseTimeShift = vUv + noiseScale * vec2( noiseGeneratorTimeShift.r, noiseGeneratorTimeShift.b );
        vec4 baseColor = texture2D( baseTexture, uvNoiseTimeShift );

        baseColor.a = alpha;
        gl_FragColor = baseColor;
    }  
    `;

  // create custom material from the shader code above
  //   that is within specially labeled script tags
  var customMaterial = new THREE.ShaderMaterial({
    uniforms: customUniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
  });

  // other material properties
  customMaterial.side = THREE.DoubleSide;

  // apply the material to a surface
  var flatGeometry = new THREE.PlaneGeometry(SCREEN_WIDTH, SCREEN_HEIGHT);
  var surface = new THREE.Mesh(flatGeometry, customMaterial);
  surface.position.set(0, 0, 150);
  scene.add(surface);

  // // GUI

  // const parameters = {
  //     baseSpeed: 0.5,
  //     noiseScale: 0.5,
  // };

  // function update() {
  //     customUniforms.baseSpeed.value = parameters.baseSpeed;
  //     customUniforms.noiseScale.value = parameters.noiseScale;
  // }

  // const gui = new GUI({ autoPlace: false });
  // container.appendChild(gui.domElement);

  // const folderSky = gui.addFolder('Sky');
  // folderSky.add(parameters, 'baseSpeed', 0, 1, 0.01).onChange(update);
  // folderSky.add(parameters, 'noiseScale', 0, 5, 0.1).onChange(update);
  // folderSky.open();

  return {
    scene,
    camera,
    renderer,
    clock,
    customUniforms,
    controls,
    resizeListenerDestroy,
    trigger,
  };
}

// FUNCTIONS
export function init(
  id,
  img,
  ratio,
  baseSpeed = 0.05,
  noiseScale = 0.5,
  scale = params.scale,
  reflectivity = params.reflectivity
) {
  var clock = new THREE.Clock();
  let container = document.getElementById(id);

  // SCENE
  let scene = new THREE.Scene();
  // scene.background = new Color(1, 1, 1);
  const cubeTextureLoader = new THREE.CubeTextureLoader();
  // cubeTextureLoader.setPath( 'textures/cube/Park2/' );

  const cubeTexture = cubeTextureLoader.load([
    // "posx.jpg", "negx.jpg",
    // "posy.jpg", "negy.jpg",
    // "posz.jpg", "negz.jpg"
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/posx.jpg',
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/negx.jpg',
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/posy.jpg',
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/negy.jpg',
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/posz.jpg',
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/negz.jpg',
  ]);

  scene.background = cubeTexture;

  const torusKnotGeometry = new THREE.TorusKnotGeometry(3, 1, 256, 32);
  const torusKnotMaterial = new THREE.MeshNormalMaterial();

  const torusKnot = new THREE.Mesh(torusKnotGeometry, torusKnotMaterial);
  torusKnot.visible = params.torusVisible;
  torusKnot.position.y = 4;
  torusKnot.scale.set(0.5, 0.5, 0.5);
  scene.add(torusKnot);

  // CAMERA
  var SCREEN_WIDTH = container.offsetWidth;
  var SCREEN_HEIGHT = parseInt(container.offsetWidth * ratio);

  let camera = new THREE.PerspectiveCamera(45, SCREEN_WIDTH / SCREEN_HEIGHT, 0.1, 200);
  camera.position.set(0, 20, 5);
  camera.lookAt(scene.position);
  scene.add(camera);

  // LIGHTS
  // const light = new THREE.DirectionalLight( 0xaabbff, 0.3 );
  // light.position.x = 300;
  // light.position.y = 250;
  // light.position.z = - 500;
  // scene.add( light );

  const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
  directionalLight.position.set(-1, 1, 1);
  scene.add(directionalLight);

  let renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
  renderer.domElement.id = id + '-threeCanvas';
  renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);
  // EVENTS
  const { destroy: resizeListenerDestroy, trigger } = WindowResize(
    renderer,
    camera,
    container,
    ratio
  );

  // scene.fog = new THREE.FogExp2(0x9999ff, 0.00025);

  ////////////
  // // CUSTOM //
  // ////////////

  THREE.ImageUtils.crossOrigin = '';

  var noiseLoader = new THREE.TextureLoader();
  noiseLoader.setCrossOrigin('anonymous');
  var noiseTexture = noiseLoader.load(
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/cloud.png'
  );
  noiseTexture.wrapS = noiseTexture.wrapT = THREE.RepeatWrapping;

  var lavaLoader = new THREE.TextureLoader();
  lavaLoader.setCrossOrigin('anonymous');
  var lavaTexture = lavaLoader.load(img);
  lavaTexture.wrapS = lavaTexture.wrapT = THREE.RepeatWrapping;

  // // use "this." to create global object
  let customUniforms = {
    baseTexture: { type: 't', value: lavaTexture },
    baseSpeed: { type: 'f', value: baseSpeed },
    noiseTexture: { type: 't', value: noiseTexture },
    noiseScale: { type: 'f', value: noiseScale },
    alpha: { type: 'f', value: 1.0 },
    time: { type: 'f', value: 1.0 },
  };

  let vertexShader = `
    varying vec2 vUv;
    void main() 
    { 
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `;

  let fragmentShader = `
    uniform sampler2D baseTexture;
    uniform float baseSpeed;
    uniform sampler2D noiseTexture;
    uniform float noiseScale;
    uniform float alpha;
    uniform float time;

    varying vec2 vUv;
    void main() 
    {
        vec2 uvTimeShift = vUv + vec2( -0.7, 1.5 ) * time * baseSpeed;	
        vec4 noiseGeneratorTimeShift = texture2D( noiseTexture, uvTimeShift );
        vec2 uvNoiseTimeShift = vUv + noiseScale * vec2( noiseGeneratorTimeShift.r, noiseGeneratorTimeShift.b );
        vec4 baseColor = texture2D( baseTexture, uvNoiseTimeShift );

        baseColor.a = alpha;
        gl_FragColor = baseColor;
    }  
    `;

  // create custom material from the shader code above
  //   that is within specially labeled script tags
  var customMaterial = new THREE.ShaderMaterial({
    uniforms: customUniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
  });

  // other material properties
  customMaterial.side = THREE.DoubleSide;

  // const groundMaterial = new THREE.MeshBasicMaterial( { color: 0xcccccc } );
  const groundGeometry = new THREE.PlaneGeometry(20, 20, 10, 10);
  const ground = new THREE.Mesh(groundGeometry, customMaterial);
  ground.rotation.x = Math.PI * -0.5;
  scene.add(ground);

  // const textureLoader = new THREE.TextureLoader();
  // 			textureLoader.load(floor, function ( map ) {

  // 				map.wrapS = THREE.RepeatWrapping;
  // 				map.wrapT = THREE.RepeatWrapping;
  // 				map.anisotropy = 16;
  // 				map.repeat.set( 4, 4 );
  // 				groundMaterial.map = map;
  // 				groundMaterial.needsUpdate = true;

  // 			} );

  // water

  const waterGeometry = new THREE.PlaneGeometry(20, 20);

  var flowLoader = new THREE.TextureLoader();
  flowLoader.setCrossOrigin('anonymous');
  var flowMap = flowLoader.load(
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/Water_1_M_Flow.jpg'
  );

  var normal1Loader = new THREE.TextureLoader();
  normal1Loader.setCrossOrigin('anonymous');
  var normal1Map = normal1Loader.load(
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/Water_1_M_Normal.jpg'
  );

  var normal2Loader = new THREE.TextureLoader();
  normal2Loader.setCrossOrigin('anonymous');
  var normal2Map = normal2Loader.load(
    'https://irl-infinites.s3.us-east-2.amazonaws.com/image/Water_1_M_Normal.jpg'
  );

  const water = new Water(waterGeometry, {
    scale: scale,
    textureWidth: 1024,
    textureHeight: 1024,
    flowMap: flowMap,
    normalMap0: normal1Map,
    normalMap1: normal2Map,
    reflectivity: reflectivity / 10.0,

    color: params.color,
    // 		scale: params.scale,
    // flowDirection: new THREE.Vector2( params.flowX, params.flowY ),
    // textureWidth: 1024,
    // textureHeight: 1024
  });

  water.position.y = 1;
  water.rotation.x = Math.PI * -0.5;
  scene.add(water);

  // flow map helper

  const helperGeometry = new THREE.PlaneGeometry(20, 20);
  const helperMaterial = new THREE.MeshBasicMaterial({ map: flowMap });
  const helper = new THREE.Mesh(helperGeometry, helperMaterial);
  helper.position.y = 1.01;
  helper.rotation.x = Math.PI * -0.5;
  helper.visible = false;
  scene.add(helper);

  // const gui = new GUI();

  // gui.addColor(params, 'color').onChange(function (value) {
  //   water.material.uniforms['color'].value.set(value);
  // });
  // gui.add(params, 'scale', 1, 10).onChange(function (value) {
  //   water.material.uniforms['config'].value.w = value;
  // });
  // gui
  //   .add(params, 'reflectivity', 0.1, 1)
  //   .step(0.1)
  //   .onChange(function (value) {
  //     water.material.uniforms['reflectivity'].value = value;
  //   });
  // gui.add(params, 'torusVisible', 0, 1).onChange(function (value) {
  //   torusKnot.visible = value;
  // });
  // // gui.add( params, 'flowX', - 1, 1 ).step( 0.01 ).onChange( function ( value ) {

  // //     water.material.uniforms[ 'flowDirection' ].value.x = value;
  // //     water.material.uniforms[ 'flowDirection' ].value.normalize();

  // // } );
  // // gui.add( params, 'flowY', - 1, 1 ).step( 0.01 ).onChange( function ( value ) {

  // //     water.material.uniforms[ 'flowDirection' ].value.y = value;
  // //     water.material.uniforms[ 'flowDirection' ].value.normalize();

  // // } );

  // gui.open();

  const controls = null; // new OrbitControls( camera, renderer.domElement );
  // controls.minDistance = 5;
  // controls.maxDistance = 50;

  return {
    scene,
    camera,
    renderer,
    clock,
    customUniforms,
    controls,
    water,
    resizeListenerDestroy,
    trigger,
  };
}

export function setParams(customUniforms, img, baseSpeed, noiseScale, onload) {
  var lavaLoader = new THREE.TextureLoader();
  lavaLoader.setCrossOrigin('anonymous');
  var lavaTexture = lavaLoader.load(img, (_) => onload());
  lavaTexture.wrapS = lavaTexture.wrapT = THREE.RepeatWrapping;
  customUniforms.baseTexture.value = lavaTexture;
  customUniforms.baseSpeed.value = baseSpeed;
  customUniforms.noiseScale.value = noiseScale;
}

export function setParams2(
  customUniforms,
  water,
  img,
  baseSpeed,
  noiseScale,
  scale,
  reflectivity,
  onload
) {
  var lavaLoader = new THREE.TextureLoader();
  lavaLoader.setCrossOrigin('anonymous');
  var lavaTexture = lavaLoader.load(img, (_) => onload());
  lavaTexture.wrapS = lavaTexture.wrapT = THREE.RepeatWrapping;
  customUniforms.baseTexture.value = lavaTexture;
  customUniforms.baseSpeed.value = baseSpeed;
  customUniforms.noiseScale.value = noiseScale;
  water.material.uniforms['config'].value.w = scale;
  water.material.uniforms['reflectivity'].value = reflectivity;
}

export function animate({ scene, camera, renderer, clock, customUniforms, controls }) {
  requestAnimationFrame(() => {
    animate({ scene, camera, renderer, clock, customUniforms, controls });
  });
  render(renderer, scene, camera);
  update(clock, customUniforms, controls);
}

export function update(clock, customUniforms, controls) {
  var delta = clock.getDelta();
  customUniforms.time.value += delta;
  // controls.update();
}

export function render(renderer, scene, camera) {
  renderer.render(scene, camera);
}
