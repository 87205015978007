import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { INFINITES_TYPES, RATIO } from './constants';
import BaseLayout from './layouts/BaseLayout';
import Home from './pages/Home';

export const renderRoutes = (routes) => (
  <Suspense fallback={<div></div>}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} {...route.props} />
                )}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: '/about',
    layout: BaseLayout,
    exact: true,
    component: lazy(() => import('./pages/About')),
  },
  // {
  //   path: '/terms-conditions',
  //   layout: BaseLayout,
  //   exact: true,
  //   component: lazy(() => import('./pages/TermsAndConditions')),
  // },
  {
    path: '/collection',
    layout: BaseLayout,
    exact: true,
    component: lazy(() => import('./pages/Collection')),
  },
  {
    path: '/collection/:id',
    layout: BaseLayout,
    exact: true,
    component: lazy(() => import('./pages/InfiniteDetail')),
  },
  {
    path: '/preview/:id',
    exact: true,
    component: lazy(() => import('./pages/Preview')),
  },
  // {
  //   path: '/create-irl',
  //   exact: true,
  //   layout: BaseLayout,
  //   component: IRLHome,
  //   props: {
  //     ratio: RATIO['9:16'],
  //   },
  // },
  {
    path: '/irl',
    exact: true,
    layout: BaseLayout,
    component: lazy(() => import('./pages/Irl')),
  },
  {
    path: '/irl/collection',
    exact: true,
    layout: BaseLayout,
    props: {
      infinitesType: INFINITES_TYPES.IRL,
    },
    component: lazy(() => import('./pages/Collection')),
  },
  {
    path: '/irl/collection/:id',
    layout: BaseLayout,
    exact: true,
    component: lazy(() => import('./pages/InfiniteDetail/InfiniteIRLDetail')),
  },
  {
    path: '/irl/preview/:id',
    exact: true,
    component: lazy(() => import('./pages/IRLPreview')),
  },
  // {
  //   path: '/a/json',
  //   exact: true,
  //   component: lazy(() => import('./pages/Admin')),
  //   props: {
  //     ratio: RATIO['9:16'],
  //   },
  // },
  {
    path: '*',
    layout: BaseLayout,
    routes: [
      {
        exact: true,
        path: '/',
        props: {
          ratio: RATIO.standard,
          canvasType: 'home',
        },
        component: Home,
      },
      {
        component: () => <Redirect to='/' />,
      },
    ],
  },
];

export default routes;
