import React, { useState, useEffect, useContext, useCallback } from "react";
import { useMoralis } from "react-moralis";
import InfinitesDetails from "./Infinites.json";
import IRLDetails from "./IRL.json";

export function useContract() {
    const w = useContext(ContractContext);
    if (!w) {
        throw new Error('Missing contract context');
    }
    return w;
}

const ContractContext = React.createContext(null);

export const NFT_CONTRACT_ADDRESS = "0xA7F767865FCe8236f71AddA56c60Cf2E91DADc00";

export const IRL_CONTRACT_ADDRESS = "0xE80201a8e706A7AC353124c004960201C8b99f4B";

export default function ContractProvider(props) {
    const { web3, isWeb3Enabled } = useMoralis();
    const [contract, setContract] = useState(null);
    const [irlContract, setIRLContract] = useState(null);

    const loadSeaPort = useCallback(async () => {
        if (isWeb3Enabled) {
            const nftContract = new web3.eth.Contract(InfinitesDetails.abi, NFT_CONTRACT_ADDRESS);
            setContract(nftContract);

            const irlContract = new web3.eth.Contract(IRLDetails.abi, IRL_CONTRACT_ADDRESS, { gasLimit: '355555' });
            setIRLContract(irlContract);
        }
    }, [isWeb3Enabled, web3, setContract, setIRLContract]);
    useEffect(() => { loadSeaPort() }, [loadSeaPort, isWeb3Enabled]);

    return (
        <ContractContext.Provider value={{ contract, irlContract }}>
            {props.children}
        </ContractContext.Provider>
    )
}