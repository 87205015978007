import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mr1: {
    marginRight: theme.spacing(1),
  },
  fullscreenExitBtnWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    background: '#00000080',
    zIndex: 1101,
  },
  fullScreenBtn: {
    color: '#fff',
  },
}));
