import React, { useEffect, useRef } from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { animate, initHome } from '../../threejs';
import bannerImg from '../../assets/banner.png';
import { Link, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Lazyload from 'react-lazyload';
import IRL12Img from '../../assets/irl_12.png';
import IRL81Img from '../../assets/irl_81.png';
import IRL101Img from '../../assets/irl_101.png';
import IRL842Img from '../../assets/irl_842.png';
import FullScreenCanvas from '../../shared/components/FullScreenCanvas';

const useStyles = makeStyles((theme) => ({
  headWrapper: {
    position: 'relative',
    marginBottom: 20,
    zIndex: 0,
  },
  decorate: {
    width: '100%',
  },
  notification: {
    top: 220,
    zIndex: 9,
    background: '#EDEDED',
    border: '2px solid #585858',
    borderRadius: 15,
    marginLeft: '10vw',
    marginRight: '10vw',
    '@media (max-width: 1400px)': {
      marginLeft: 20,
      marginRight: 20,
    },
    '@media (max-width: 1300px)': {
      top: 160,
    },
    [theme.breakpoints.down('sm')]: {
      top: 70,
    },
    '& h6': {
      color: '#555',
      fontWeight: 500,
    },
    '& p': {
      color: '#2A2A2A',
      marginTop: 30,
    },
    '& $linkBtn': {
      marginLeft: 'auto',
      marginTop: 30,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: -30,
    right: -30,
  },
  bannerWrapper: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  px7vw: {
    paddingLeft: '7vw',
    paddingRight: '7vw',
  },
  canvasWrapper: {
    width: '100%',
    // maxWidth: 600,
    // margin: 'auto',
    position: 'relative',
    '&:hover': {
      '& .fs__btn-wrapper': {
        display: 'flex',
        background: 'linear-gradient(90deg, #00000080, transparent)',
      },
    },
  },
  btn: {
    marginRight: 36,
    marginBottom: 10,
    '@media (max-width: 760px)': {
      flexBasis: '100%',
      marginRight: 0,
    },
  },
  'btn--blue': {
    background: '#0011FF',
    border: '1px soild #0011ff !important',
    color: '#acacac',
    '&:hover': {
      background: '#0210d8 !important',
    },
  },
  'btn--cyan': {
    background: '#2BCDE4',
    color: 'white',
    '&:hover': {
      background: '#2bc1d6 !important',
    },
  },
  'btn--irl': {
    background: '#02ffff',
    border: '1px solid #02ffff !important',
    color: 'black',
    '&:hover': {
      background: '#2bc1d6 !important',
      border: '1px solid #2bc1d6 !important',
    },
  },
  'btn--last': {
    marginBottom: 10,
    '@media (max-width: 760px)': {
      width: '100%',
    },
  },
  linkBtn: {
    textDecoration: 'none !important',
    '@media (max-width: 760px)': {
      flexBasis: '100%',
    },
  },
  logoImg: {
    marginLeft: theme.spacing(1),
    height: '100%',
  },

  intro: {
    marginTop: 130,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: -40,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  'intro--text': {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  hr: {
    marginTop: 145,
    marginBottom: 110,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      marginTop: 70,
      marginBottom: 50,
    },
  },
  'infinite-ai': {
    marginTop: 70,
    marginBottom: 130,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      marginBottom: 60,
    },
  },
  'infinite-irl': {
    marginBottom: 130,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
    },
  },
  'color--black': {
    color: '#000',
  },
  title: {
    marginTop: 110,
    marginBottom: 140,
    marginLeft: -35,
    marginRight: -35,
    [theme.breakpoints.down('sm')]: {
      marginTop: 70,
      marginBottom: 90,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  'width--100': {
    width: '100%',
  },
  previewWrapper: {
    marginTop: 150,
    marginBottom: 150,
    [theme.breakpoints.down('sm')]: {
      marginTop: 70,
      marginBottom: 70,
    },
  },
  img: {
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: 'rgb(4 17 29 / 25%) 0px 0px 8px 0px',
      opacity: 0.8,
      transition: 'all 0.05s ease 0s',
      cursor: 'pointer',
    },
  },
  previewSmall: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  preview__caption: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  'font-weight--500': {
    fontWeight: 500,
  },
  'font-weight--bold': {
    fontWeight: 600,
  },
  social: {
    width: 54,
    height: 54,
    background: theme.palette.text.primary,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const INFINITE_ID = '01';

const Home = (props) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]);

  const classes = useStyles();

  const threeControls = useRef(null);

  useEffect(() => {
    const makeAnimateCanvas = () => {
      const id = `three${INFINITE_ID.padStart(2, '0')}`;
      threeControls.current = initHome(
        id,
        `${process.env.PUBLIC_URL}/image/Infinites${INFINITE_ID.padStart(2, '0')}.png`,
        props.ratio
      );

      animate(threeControls.current);
    };

    makeAnimateCanvas();

    return () => {
      if (threeControls.current) threeControls.current.resizeListenerDestroy();
    };
  }, [props.ratio]);

  const { width: windowWidth } = useWindowDimensions();

  return (
    <>
      <div className={classes.headWrapper}>
        <div className={classes.decorate} style={{ height: windowWidth * 0.5625 }} />

        <Lazyload className={classes.bannerWrapper}>
          <img src={bannerImg} alt='banner' width='100%' />
        </Lazyload>
        <div className={classNames('container-fluid', classes.px7vw)}>
          <Typography
            variant='subtitle1'
            align='center'
            className={classNames(
              'font--archivo',
              classes['color--black'],
              classes['infinite-ai'],
              classes['font-weight--bold']
            )}
          >
            INFINITES AI by FAR
          </Typography>

          <Box display='flex' justifyContent='center' flexWrap='wrap' mt={2}>
            <Button color='secondary' variant='outlined' size='large' className={classes.btn}>
              <a
                href='https://etherscan.io/address/0xA7F767865FCe8236f71AddA56c60Cf2E91DADc00'
                target='_blank'
                rel='noreferrer'
                className='width--full'
              >
                Smart Contract
              </a>
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              size='large'
              className={classNames(classes.btn, classes['btn--cyan'])}
            >
              <a
                href='https://opensea.io/collection/infinites-ai'
                target='_blank'
                rel='noreferrer'
                className='width--full'
              >
                Buy on OpenSea
              </a>
            </Button>
            <Link to='/collection' className={classes.linkBtn}>
              <Button
                color='secondary'
                variant='outlined'
                size='large'
                className={classNames(classes.btn, classes['btn--blue'], classes['btn--last'])}
              >
                See Collection
              </Button>
            </Link>
            <Link to='#irl' className={classes.linkBtn}>
              <Button
                color='secondary'
                variant='outlined'
                size='large'
                className={classNames(classes.btn, classes['btn--irl'], classes['btn--last'])}
              >
                Explore IRL
              </Button>
            </Link>
          </Box>
          <Box display='flex' justifyContent='center' mt={2}>
            <a
              href='https://discord.gg/2bPQMB78nb'
              target='_blank'
              rel='noreferrer'
              className={classes.social}
            >
              <svg fill='#FFFFFF' viewBox='0 0 22 16' height={20} width={30}>
                <path d='M8.11.5c-.28.002-2.574.067-4.996 1.873 0 0-2.584 4.665-2.584 10.408 0 0 1.507 2.593 5.473 2.719 0 0 .664-.792 1.202-1.477-2.278-.685-3.14-2.108-3.14-2.108s.18.126.502.307c.018 0 .035.019.07.036.055.035.108.054.162.09.448.252.896.45 1.31.611.736.307 1.615.576 2.639.774 1.346.252 2.925.342 4.646.019a12.954 12.954 0 002.603-.774 10.118 10.118 0 002.062-1.063s-.896 1.458-3.247 2.125c.538.666 1.185 1.439 1.185 1.439 3.965-.126 5.473-2.72 5.473-2.7 0-5.746-2.584-10.409-2.584-10.409C16.32.446 13.861.5 13.861.5l-.251.288c3.05.918 4.468 2.27 4.468 2.27a14.856 14.856 0 00-5.4-1.711 15.048 15.048 0 00-3.626.036c-.107 0-.197.019-.306.035-.628.072-2.153.288-4.073 1.135-.663.288-1.059.505-1.059.505S5.088 1.635 8.317.717L8.137.5h-.028zm-.457 6.645c1.022 0 1.849.882 1.83 1.981 0 1.1-.808 1.982-1.83 1.982-1.005 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981zm6.55 0c1.004 0 1.83.882 1.83 1.981 0 1.1-.809 1.982-1.83 1.982-1.006 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981z'></path>
              </svg>
            </a>
            <a
              href='https://twitter.com/infinites_ai?s=21'
              target='_blank'
              rel='noreferrer'
              className={classes.social}
            >
              <svg fill='#FFFFFF' viewBox='0 0 18 16' height={20} width={30}>
                <path d='M.09 13.791c1.992.14 3.728-.344 5.327-1.571-.816-.098-1.527-.311-2.127-.786-.584-.466-1.032-1.047-1.272-1.841h1.48c.008-.033.016-.066.024-.107-.816-.237-1.512-.663-2.032-1.342-.52-.67-.775-1.448-.8-2.3.52.148 1.016.295 1.52.434.016-.033.04-.065.056-.098-.72-.606-1.24-1.334-1.431-2.275a3.92 3.92 0 01.391-2.7c2 2.389 4.511 3.715 7.598 3.936-.096-.778-.104-1.498.16-2.202.912-2.463 3.983-3.249 5.894-1.481.216.196.4.229.632.147.632-.229 1.255-.474 1.903-.72-.248.81-.784 1.408-1.415 1.989.615-.164 1.231-.336 1.839-.5.024.025.048.041.072.066-.464.491-.912 1.007-1.415 1.449-.272.237-.36.458-.376.818-.144 4.01-1.752 7.25-5.175 9.289-3.487 2.07-7.077 1.947-10.612-.025-.064-.04-.12-.09-.24-.18z'></path>
              </svg>
            </a>
            <a
              href='https://infinitesai.medium.com/'
              target='_blank'
              rel='noreferrer'
              className={classes.social}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                clipRule='evenodd'
                width={24}
                height={24}
                fill='#FFF'
              >
                <path d='M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z' />
              </svg>
            </a>
          </Box>

          <Grid container spacing={10} className={classes.intro}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Typography
                variant='h6'
                className={classNames('color--gray3F', classes['intro--text'])}
              >
                A collection of 512 artworks created by AI, trained by the Great Masters, and
                augmented on-chain
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <FullScreenCanvas
                threeControlsRef={threeControls}
                disabledRotate
                renderCanvas={(canvasChildren) => (
                  <div
                    id={`three${INFINITE_ID.padStart(2, '0')}`}
                    className={classNames(classes.canvasWrapper, 'fs__canvas')}
                  >
                    {canvasChildren}
                  </div>
                )}
              />
            </Grid>
          </Grid>

          <hr className={classes.hr} />

          <Typography
            variant='subtitle1'
            align='center'
            className={classNames(
              'font--archivo',
              classes['color--black'],
              classes['font-weight--bold']
            )}
          >
            WHAT ARE INFINITES AI?
          </Typography>
          <Typography
            variant='h6'
            align='center'
            className={classNames(classes.title, classes['color--black'])}
          >
            Infinites AI is a collection of unique 512 artworks generated at the time of the minting
            process.
          </Typography>

          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant='subtitle2' className={classes['color--black']}>
                Each infinite was created utilizing the intersection of two generative processes: A
                StyleGAN trained with a curation of artworks from the Great Masters, and an on-chain
                generative process that augmented a selection of the generated pieces from the first
                process.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant='subtitle2' className={classes['color--black']}>
                A set of images previously generated by the StyleGAN are used by a customized
                software to create an on-chain augmentation. The custom software is written in
                three.js and generates unique artifacts based on Ethereum transactions.
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent='space-between' className={classes.previewWrapper}>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                height='100%'
              >
                <Link
                  className={classNames(classes.previewSmall, classes.noDecoration)}
                  to='/collection/1'
                >
                  <img
                    src='https://infinites.s3.us-east-2.amazonaws.com/images/Infinites01.jpeg'
                    className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                    alt='preview'
                  />
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    className={classes['preview__caption']}
                  >
                    <Typography
                      variant='subtitle2'
                      className={classNames(classes['color--black'], classes['font-weight--500'])}
                    >
                      #1
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      className={classNames(classes['color--black'], classes['font-weight--500'])}
                    >
                      Infinite
                    </Typography>
                  </Box>
                </Link>
                <Link
                  className={classNames(classes.previewSmall, classes.noDecoration)}
                  to='/collection/2'
                >
                  <img
                    src='https://infinites.s3.us-east-2.amazonaws.com/images/Infinites02.jpeg'
                    className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                    alt='preview'
                  />
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    className={classes['preview__caption']}
                  >
                    <Typography
                      variant='subtitle2'
                      className={classNames(classes['color--black'], classes['font-weight--500'])}
                    >
                      #2
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      className={classNames(classes['color--black'], classes['font-weight--500'])}
                    >
                      Infinite
                    </Typography>
                  </Box>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={7}>
              <Link to='/collection/44' className={classes.noDecoration}>
                <img
                  src='https://infinites.s3.us-east-2.amazonaws.com/images/Infinites44.jpeg'
                  className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                  alt='preview'
                />
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className={classes['preview__caption']}
                >
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    #44
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    Infinite
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>

          <hr className={classes.hr} />

          <Typography
            variant='subtitle1'
            align='center'
            className={classNames(
              'font--archivo',
              classes['color--black'],
              classes['font-weight--bold'],
              classes['infinite-irl']
            )}
            id='irl'
          >
            INFINITES IRL
          </Typography>

          <Box display='flex' justifyContent='center' flexWrap='wrap' mt={2}>
            <Button color='secondary' variant='outlined' size='large' className={classes.btn}>
              <a
                href='https://etherscan.io/address/0xe80201a8e706a7ac353124c004960201c8b99f4b'
                target='_blank'
                rel='noreferrer'
                className='width--full'
              >
                Smart Contract
              </a>
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              size='large'
              className={classNames(classes.btn, classes['btn--cyan'])}
            >
              <a
                href='https://opensea.io/collection/infinites-irl'
                target='_blank'
                rel='noreferrer'
                className='width--full'
              >
                Buy on OpenSea
              </a>
            </Button>
            <Link to='/irl/collection' className={classes.linkBtn}>
              <Button
                color='secondary'
                variant='outlined'
                size='large'
                className={classNames(classes.btn, classes['btn--blue'], classes['btn--last'])}
              >
                See Collection
              </Button>
            </Link>
          </Box>

          <Grid container spacing={10} className={classes.intro}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <Typography
                variant='h6'
                className={classNames('color--gray3F', classes['intro--text'])}
              >
                Infinites Real Life (IRL), is a collection of 1024 unique artworks generated at the
                time of the minting process.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <Link className={classes.noDecoration} to='/irl/collection/12'>
                <img
                  src={IRL12Img}
                  className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                  alt='preview'
                />
              </Link>
            </Grid>
          </Grid>

          <hr className={classes.hr} />

          <Typography
            variant='subtitle1'
            align='center'
            className={classNames(
              'font--archivo',
              classes['color--black'],
              classes['font-weight--bold']
            )}
          >
            WHAT ARE INFINITES IRL?
          </Typography>
          <Typography
            variant='h6'
            align='center'
            className={classNames(classes.title, classes['color--black'])}
          >
            Infinites IRL builds on Infinites AI.
          </Typography>

          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant='subtitle2' className={classes['color--black']}>
                Infinites Real Life (IRL), is a collection of 1024 unique artworks generated at the
                time of the minting process. This collection builds on Infinites AI. Like the
                originals, each Infinite IRL was created utilizing the intersection of two
                generative processes: A StyleGAN trained with a curation of artworks from the Great
                Masters, and an On-Chain generative process that augmented a selection of the
                generated pieces from the first process.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant='subtitle2' className={classes['color--black']}>
                In addition, another On-Chain generative process was utilized to generate the final
                artwork, adding further depth. The artifacts are coded using custom software that is
                written in three.js and utilizes the Ethereum data to drive the stylistic gestures.
                IRLs have been explicitly designed for exhibition display.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent='space-between'
            spacing={10}
            className={classes.previewWrapper}
          >
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Link
                className={classNames(classes.previewSmall, classes.noDecoration)}
                to='/irl/collection/81'
              >
                <img
                  src={IRL81Img}
                  className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                  alt='preview'
                />
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className={classes['preview__caption']}
                >
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    #81
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    IRL
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Link
                className={classNames(classes.previewSmall, classes.noDecoration)}
                to='/irl/collection/101'
              >
                <img
                  src={IRL101Img}
                  className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                  alt='preview'
                />
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className={classes['preview__caption']}
                >
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    #101
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    IRL
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Link
                className={classNames(classes.previewSmall, classes.noDecoration)}
                to='/irl/collection/842'
              >
                <img
                  src={IRL842Img}
                  className={classNames(classes['width--100'], 'border--radius-5', classes.img)}
                  alt='preview'
                />
                <Box
                  display='flex'
                  justifyContent='space-between'
                  className={classes['preview__caption']}
                >
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    #842
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classNames(classes['color--black'], classes['font-weight--500'])}
                  >
                    IRL
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Home;
